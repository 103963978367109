<template>
  <div class="wrapper">
    <h1>Nyktra samkväm</h1>
    <img class="party" src="/bild.webp">
    <p>Det är klart att det går att festa nyktert. Ett alkoholfritt samkväm kan på många sätt överglänsa en fest som slutar i <i>alkoångest</i>.</p>
    <p>Vill du också vara en del av den nyktra gemenskapen? </p>
    <p><strong>Håll utkik efter nyktra samkväm på en plats nära dig!</strong></p>
  </div>

</template>

<script setup>

</script>

<style>
#app {
  font-family: Quattrocento;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

h1,
h2,
h3 {
  font-family: Oswald;
  font-weight: bold;
}

.wrapper{
  width: 500px; 
  margin: 0 auto;
  max-width: 100%; 
}

img.party{
  width:400px;
  max-width: 100%; 
}
</style>
